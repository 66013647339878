<template>
  <v-form ref="form">
    <v-row class="mx-3 mt-4">
      <v-col
        cols="6"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Share term'"
          :rules="{ required: true }"
        >
          <common-input-select
            v-model="value.share_terms_id"
            label="Share Term"
            placeholder="Select share term"
            :items="shareTerms"
            item-text="name"
            item-value="id"
            :error-messages="errors"
            :hide-details="errors.length === 0"
            @change="updateValue"
          />
        </validation-provider>
      </v-col>
      <v-col
        cols="6"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Financing round'"
          :rules="{ required: true }"
        >
          <common-input-select
            v-model="value.financing_round_id"
            label="Financing Round"
            placeholder="Financing Round"
            :items="financingRounds"
            item-text="name"
            item-value="id"
            :error-messages="errors"
            :hide-details="errors.length === 0"
            @change="updateValue"
          />
        </validation-provider>
      </v-col>
      <v-col
        cols="12"
        lg="6"
        md="6"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Name'"
          :rules="{ required: true, max: 255 }"
        >
          <common-input-text-field
            v-model="value.name"
            label="Name"
            color="primary"
            :error-messages="errors"
            :hide-details="errors.length === 0"
            @input="updateValue"
          />
        </validation-provider>
      </v-col>
      <v-col
        cols="12"
        lg="6"
        md="6"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Prefix'"
          :rules="{ required: true, max: 20 }"
        >
          <common-input-text-field
            v-model="value.prefix"
            label="Prefix"
            color="primary"
            :error-messages="errors"
            :hide-details="errors.length === 0"
            @input="updateValue"
          />
        </validation-provider>
      </v-col>
      <v-col
        cols="12"
        lg="6"
        md="6"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Exercise price per share'"
          :rules="{ required: true, min_value: 0 }"
        >
          <common-input-currency-input
            v-model="value.strike_price"
            label="Exercise Price Per Share"
            color="primary"
            :error-messages="errors"
            :hide-details="errors.length === 0"
            :options="{ locale: 'en-US', precision: 2 }"
            prepend-inner-icon="mdi-currency-usd"
            @input="updateValue"
          />
        </validation-provider>
      </v-col>
      <v-col
        cols="12"
        lg="6"
        md="6"
      >
        <validation-provider
          v-slot="{ errors }"
          :name="'Board approval date'"
          :rules="{ required: true }"
        >
          <input-template-date
            v-model="boardApprovalDate"
            :mask="'##/##/####'"
            :error-messages="errors"
            :hide-details="errors.length === 0"
            :input-templ="{label: 'Board approval date', mandatory: true}"
            clearable
            @change="updateValue"
          />
        </validation-provider>
      </v-col>
      <v-col cols="12">
        <notes-and-documents-form
          :id="warrantTermId"
          :mode="mode"
          :is-submit="isSubmit"
          :internal-dialog="internalDialog"
          :type="CONSTS.NOTES_DOCUMENT_API_TYPE.WARRANT_TERMS"
          @navToList="$emit('navToList', true)"
        />
      </v-col>
    </v-row>
    <!-- Debt Specific Fields -->
    <interest-form
      v-if="isDebtType"
      v-model="interestTerms"
      @update="updateInterest"
    />
  </v-form>
</template>
<script>
  import moment from 'moment'
  import InputTemplateDate from '@/components/common/fmsvc/form-templates/InputTemplateDate'
  import InterestForm from './InterestForm'
  import NotesAndDocumentsForm from './NotesAndDocumentsForm.vue'

  export default {
    name: 'ConvertibleTermForm',

    components: {
      InterestForm,
      NotesAndDocumentsForm,
      InputTemplateDate,
    },

    props: {
      financingRounds: {
        type: Array,
        required: true,
      },
      value: {
        type: Object,
        required: true,
      },
      shareTerms: {
        type: Array,
        required: true,
      },
      warrantTermId: {
        type: String,
      },
      mode: {
        type: String,
        default: 'create',
      },
      isSubmit: {
        type: Boolean,
        default: false,
      },
      internalDialog: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      datePickerAttr: { max: new Date().toISOString() },
      conversionDiscount: 0,
      interestTerms: {},
      notesData: {},
      boardApprovalDate: '',
    }),

    computed: {
      isDebtType () {
        return this.value.convertible_type === this.CONSTS.CONVERTIBLE_TERM_TYPES.DEBT
      },
      internalValue: {
        get () {
          return this.value
        },
        set (val) {
          this.$emit('input', val)
        },
      },
    },

    watch: {
      value: {
        handler (val) {
          if (val) {
            if (val.conversion_discount) {
              this.conversionDiscount = val.conversion_discount * 100
            }
            this.boardApprovalDate = val.board_approved_on ? moment(val.board_approved_on).format('MM/DD/YYYY') : ''
          }
        },
        immediate: true,
      },
      conversionDiscount: {
        handler (val) {
          if (val) {
            this.value.conversion_discount = val / 100
          }
        },
        immediate: true,
      },
      boardApprovalDate: {
        handler (val) {
          if (val) {
            this.value.board_approved_on = moment(val).format('YYYY-MM-DD')
          }
        },
        immediate: true,
      },
    },

    methods: {
      updateValue () {
        this.clearBlankData()
        this.$emit('update', this.value)
      },
      updateInterest (value) {
        this.value.interest_terms = value
        this.value.convertible_debt_terms = {}
      },
      clearBlankData () {
        Object.keys(this.value).forEach((key) => {
          if (this.value[key] === null || this.value[key] === undefined) {
            delete this.value[key]
          }
        })
      },
    },
  }
</script>
