var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form"},[_c('v-row',{staticClass:"mx-3 mt-4"},[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":'Share term',"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-select',{attrs:{"label":"Share Term","placeholder":"Select share term","items":_vm.shareTerms,"item-text":"name","item-value":"id","error-messages":errors,"hide-details":errors.length === 0},on:{"change":_vm.updateValue},model:{value:(_vm.value.share_terms_id),callback:function ($$v) {_vm.$set(_vm.value, "share_terms_id", $$v)},expression:"value.share_terms_id"}})]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":'Financing round',"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-select',{attrs:{"label":"Financing Round","placeholder":"Financing Round","items":_vm.financingRounds,"item-text":"name","item-value":"id","error-messages":errors,"hide-details":errors.length === 0},on:{"change":_vm.updateValue},model:{value:(_vm.value.financing_round_id),callback:function ($$v) {_vm.$set(_vm.value, "financing_round_id", $$v)},expression:"value.financing_round_id"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","lg":"6","md":"6"}},[_c('validation-provider',{attrs:{"name":'Name',"rules":{ required: true, max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-text-field',{attrs:{"label":"Name","color":"primary","error-messages":errors,"hide-details":errors.length === 0},on:{"input":_vm.updateValue},model:{value:(_vm.value.name),callback:function ($$v) {_vm.$set(_vm.value, "name", $$v)},expression:"value.name"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","lg":"6","md":"6"}},[_c('validation-provider',{attrs:{"name":'Prefix',"rules":{ required: true, max: 20 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-text-field',{attrs:{"label":"Prefix","color":"primary","error-messages":errors,"hide-details":errors.length === 0},on:{"input":_vm.updateValue},model:{value:(_vm.value.prefix),callback:function ($$v) {_vm.$set(_vm.value, "prefix", $$v)},expression:"value.prefix"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","lg":"6","md":"6"}},[_c('validation-provider',{attrs:{"name":'Exercise price per share',"rules":{ required: true, min_value: 0 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-currency-input',{attrs:{"label":"Exercise Price Per Share","color":"primary","error-messages":errors,"hide-details":errors.length === 0,"options":{ locale: 'en-US', precision: 2 },"prepend-inner-icon":"mdi-currency-usd"},on:{"input":_vm.updateValue},model:{value:(_vm.value.strike_price),callback:function ($$v) {_vm.$set(_vm.value, "strike_price", $$v)},expression:"value.strike_price"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","lg":"6","md":"6"}},[_c('validation-provider',{attrs:{"name":'Board approval date',"rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-template-date',{attrs:{"mask":'##/##/####',"error-messages":errors,"hide-details":errors.length === 0,"input-templ":{label: 'Board approval date', mandatory: true},"clearable":""},on:{"change":_vm.updateValue},model:{value:(_vm.boardApprovalDate),callback:function ($$v) {_vm.boardApprovalDate=$$v},expression:"boardApprovalDate"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('notes-and-documents-form',{attrs:{"id":_vm.warrantTermId,"mode":_vm.mode,"is-submit":_vm.isSubmit,"internal-dialog":_vm.internalDialog,"type":_vm.CONSTS.NOTES_DOCUMENT_API_TYPE.WARRANT_TERMS},on:{"navToList":function($event){return _vm.$emit('navToList', true)}}})],1)],1),(_vm.isDebtType)?_c('interest-form',{on:{"update":_vm.updateInterest},model:{value:(_vm.interestTerms),callback:function ($$v) {_vm.interestTerms=$$v},expression:"interestTerms"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }